<template>
    <div class="treeselect">
        <treeselect v-model="treeselectArr" placeholder="请选择用户归属" :flat="true" :multiple="true" :options="options" :normalizer="normalizer" @input="treeselectChange"/>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {AgentTree} from "@/request/api/userManage";
export default {
    components: {Treeselect},
    props: {
        isDateShow:{
            type:Boolean,
            default:true,
        }
    },
    data() {
        return {
            options:[],
            treeselectArr:[],
            normalizer(node) {
                return {
                    id: node.agentNo,
                    label: node.agentName,
                    children: node.childs,
                }
            },
        };
    },
    watch: {
        isDateShow: function (val) {
            this.treeselectChange()
            this.treeselectArr = []
        }
    },
    created() {
        AgentTree({}).then((res) => {
            if (res.code === 200) {
                this.options = res.data.lists
            }
        });
    },
    methods: {
        treeselectChange(value){
            this.$emit('treeDataChange', value);
        },
    }
};
</script>
<style lang="less" scoped>

</style>
